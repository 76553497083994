import { APICall } from '../core';
import { isString } from '../../helpers/utils';
import DataFilter from './DataFilter';

export default class DataFilterService {

    constructor(bc) {
        this.bc = bc;
    }

    getDataFilters(table) {
        if (!table || !isString(table) || table === '') {
            return Promise.reject({
                status: 0,
                message: 'Table name was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/data-filters/${table}`,
            'GET'
        );
        return request.send();
    }

    createDataFilter(table, df) {
        if (!table || !isString(table) || table === '') {
            return Promise.reject({
                status: 0,
                message: 'Table name was not specified properly.'
            });
        }
        if (!(df instanceof DataFilter)) {
            return Promise.reject({
                status: 0,
                message: 'Data filter data argument must be an instance of the DataFilter class.'
            });
        }
        if (!df.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid data filter data.',
                errors: df.getValidationErrors()
            });
        }
        let payload = df.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/data-filters/${table}`,
            'POST',
            payload
        );
        return request.send();
    }

    removeDataFilter(id) {
        if (!id || !isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Data filter id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/data-filters/${id}`,
            'DELETE'
        );
        return request.send();
    }

    updateDataFilter(id, df) {
        if (!id || !isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Data filter id was not specified properly.'
            });
        }
        if (!(df instanceof DataFilter)) {
            return Promise.reject({
                status: 0,
                message: 'Data filter data argument must be an instance of the DataFilter class.'
            });
        }
        if (!df.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid data filter data.',
                errors: df.getValidationErrors()
            });
        }
        let payload = df.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/data-filters/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }
}