import {
    isObject,
    objectKeysToSnakeCase,
    extractJsonProps
} from '../../helpers/utils';
import { Validator } from '../core';

const DATA_FILTER_SCHEMA = {
    id: { type: 'string' },
    name: { type: 'string', rules: { required: true } }
};

export default class DataFilter {

    constructor() {
        this.validator = new Validator();
        /* JSON properties */
        this._id = null;
        this._name = null;
        this._filter = null;
    }

    loadToJSON() {
        // get all properties, ignore methods
        var data = JSON.parse(JSON.stringify(this));
        // extract properties that are part of the json
        extractJsonProps(data);
        return objectKeysToSnakeCase(data);
    }

    loadFromJSON(data) {
        if (!isObject(data)) {
            throw new Error('Data filter data was not specified properly.');
        }
        // Use json properties keys as setter methods for class properties
        for (let i in data) {
            this[i] = data[i];
        }
    }

    /* SETTER METHODS */

    set id(id) {
        this._id = id;
    }

    set name(name) {
        this._name = name;
    }

    set filter(filter) {
        this._filter = filter;
    }

    /* GETTER METHODS */

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get filter() {
        return this._filter;
    }

    validate() {
        if (this.validator.validate(DATA_FILTER_SCHEMA, this)) {
            return true;
        }
        return false;
    }

    getValidationErrors() {
        return this.validator.getValidationErrors();
    }
}