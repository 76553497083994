import { APICall } from '../core';
import { isString, isNumber } from '../../helpers/utils';

export default class StatsService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * 
     * @param {string} id User id
     * @param {string} type Statistic type (year|month)
     * @param {number} month 
     * @param {number} year 
     */
    userOrders(id, type, month, year) {
        let queryParams = [];
        let queryString = '';
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/user/${id}/orders${queryString}`,
            'GET'
        );
        return request.send();
    }

    
    /**
     * Gets the top five users with most orders
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array 
     * with the top five users with most orders or an Error with the problem.
     */
     usersWithMostOrders() {
		const request = new APICall(
			this.bc.apiKey,
			this.bc.auth.jwtData.csrf,
			`${this.bc.baseUrl}/v1/stats/users/most-orders`,
			'GET'
		);
		return request.send();
	}

    /**
     * Gets the top five users with most purchases
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array 
     * with the top five users with most purchases or an Error with the problem.
     */
     usersWithMostPurchases() {
		const request = new APICall(
			this.bc.apiKey,
			this.bc.auth.jwtData.csrf,
			`${this.bc.baseUrl}/v1/stats/users/most-purchases`,
			'GET'
		);
		return request.send();
	}

    /**
     * Gets the top five users with recent purchases
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array 
     * with the top five users with recent purchases or an Error with the problem.
     */
     usersWithRecentPurchases() {
		const request = new APICall(
			this.bc.apiKey,
			this.bc.auth.jwtData.csrf,
			`${this.bc.baseUrl}/v1/stats/users/recent-purchases`,
			'GET'
		);
		return request.send();
	}

    /**
     * Gets the last five registered users
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array 
     * with the last five registered users or an Error with the problem.
     */
     signups() {
		const request = new APICall(
			this.bc.apiKey,
			this.bc.auth.jwtData.csrf,
			`${this.bc.baseUrl}/v1/stats/users/signups`,
			'GET'
		);
		return request.send();
	}
    /**
     * Gets the users sorted by activity status and total number of users
     *   
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object 
     * with three properties(count of total,active and inactive users) or an Error with the problem.
     */
    usersCountByActivityStatus() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/users/activity-status`,
            'GET'
        );
        return request.send();
    }

    /**
     * Gets the count of full registered users and pending users
     * 
     * @returns {Promise} Returns a Promise that, when fullfilled, will either return an Object 
     * with two properties (full registered users and pending users) or an Error with the problem.
     */
    registrations() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/users/registrations`,
			'GET'
        );
        return request.send();
    }

    /**
     * Gets all registered users in a current month
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array 
     * with all registered users in a current month or an Error with the problem.
     */
     monthlySignups(date) {
        let queryParam = '';
        let queryString = '';
        if(isString(date) && date !== '') {
            queryParam = `date=${date}`; 
        }
        if (queryParam !== '') {
            queryString = `?${queryParam}`;
        }
		const request = new APICall(
			this.bc.apiKey,
			this.bc.auth.jwtData.csrf,
			`${this.bc.baseUrl}/v1/stats/users/monthly-signups${queryString}`,
			'GET'
		);
		return request.send();
	}

    /**
     * Gets the number of the users that registered in the past 24 hours
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object 
     * with one property (number of the last registered users in the past 24 hours) or an Error with the problem. 
     */
    lastRegistrations() {
        const request = new APICall(
			this.bc.apiKey,
			this.bc.auth.jwtData.csrf,
			`${this.bc.baseUrl}/v1/stats/users/last-registrations`,
			'GET'
		);
		return request.send();
    }

    /**
     * Gets the top two locations with most registered users and how many users they have.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array
     * with the top two locations with most registered users or an Error with the problem.
     */
    topTwoLocationsWithMostUsers() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/users/top-locations`,
			'GET'
        );
        return request.send();
    }

    /**
     * Gets the number of users using social login
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object
     * with one property (number of users using social login) or an Error with the problem.
     */
     socialLogins() {
		const request = new APICall(
			this.bc.apiKey,
			this.bc.auth.jwtData.csrf,
			`${this.bc.baseUrl}/v1/stats/users/social-logins`,
			'GET'
		);
		return request.send();
	}

    /**
     * 
     * @param {string} type Statistic type (year|month|day)
     * @param {number} year 
     * @param {number} month 
     * @param {number} day 
     */
    dashboardOrdersCount(type, year, month, day) {
        let queryParams = [];
        let queryString = '';
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(day)) {
            queryParams.push(`day=${day}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/orders/count${queryString}`,
            'GET'
        );
        return request.send();
    }

    /**
     * 
     * @param {string} type Statistic type (year|month|day)
     * @param {number} year 
     * @param {number} month 
     * @param {number} day 
     */
    dashboardOrdersAvgPrice(type, year, month, day) {
        let queryParams = [];
        let queryString = '';
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(day)) {
            queryParams.push(`day=${day}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/orders/avg-price${queryString}`,
            'GET'
        );
        return request.send();
    }

    /**
     * 
     * @param {string} type Statistic type (year|month|day)
     * @param {number} year 
     * @param {number} month 
     * @param {number} day 
     * @param {number} limit Number of results to be returned
     */
    dashboardTopSellingProducts(type, year, month, day, limit) {
        let queryParams = [];
        let queryString = '';
        if (isString(type) & type !== '') {
            queryParams.push(`type=${type}`);
        }
        if (isNumber(year)) {
            queryParams.push(`year=${year}`);
        }
        if (isNumber(month)) {
            queryParams.push(`month=${month}`);
        }
        if (isNumber(day)) {
            queryParams.push(`day=${day}`);
        }
        if (isNumber(limit)) {
            queryParams.push(`limit=${limit}`);
        }
        if (queryParams.length > 0) {
            queryString += `?${queryParams.join('&')}`;
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/products/top-selling${queryString}`,
            'GET'
        );
        return request.send();
    }

    dashboardProductsCounters() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/dashboard/products/counters`,
            'GET'
        );
        return request.send();
    }

	/**
	 * Gets domestic products
	 * @returns {Promise} Returns a promise that, when fulfilled, will either return an Object
	 * with the total number of products and number of domestic products or an Error with the problem.
	 */
	domesticProducts() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/domestic-products`,
			'GET'
		);
		return request.send();
	}
	/** 
	 * Gets the out of stock index for five products.
	 * @returns {Promise} Returns a promise that, when fulfilled, will either return an Array 
	 * with the out of stock index or an Error with the problem.
	 */
	outOfStockIndex() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/out-of-stock-index`,
            'GET'
        );
        return request.send();
	}

	/**
	 * Gets the order pipeline
	 * @returns Promise} Returns a promise that, when fulfilled, will either return an Array
	 * with the orders pipeline or an Error with the problem.
	 */
	ordersPipeline() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/orders-pipeline`,
            'GET'
        );
        return request.send();
	}

	/**
	 * Gets products with price above thousand
	 * @returns {Promise} Returns a promise that, when fulfilled, will either return an Object 
	 * with the total number of products and number of products with price above thousand 
	 * or an Error with the problem.
	 */
	productsWithPriceOverThousand() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/price-above-thousand`,
            'GET'
        );
        return request.send();
	}

	/**
	 * Gets the last five orders
	 * @returns {Promise} Returns a promise that, when fulfilled, will either return an Array 
     * with the latest orders or an Error with the problem.
	 */
	latestOrders() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/latest-orders`,
            'GET'
        );
        return request.send();
	}

	/**
	 * Gets orders with pending status
	 * @returns {Promise} Returns a promise that, when fulfilled, will either return an Object 
	 * with the total number of orders 
	 * and number of orders with pending status or an Error with the problem.
	 */
	pendingOrders() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/pending-orders`,
            'GET'
        );
        return request.send();
	}
	
	/**
	 * Gets the top five best sellers products
	 * @returns {Promise} Returns a promise that, when fulfilled, will either return an Array 
	 * with the top five best seller products or an Error with the problem.
	 */
	bestSellersProducts() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/best-sellers`,
            'GET'
        );
        return request.send();
	}

	/**
	 * Gets orders with delivered status
	 * @returns {Promise} Returns a promise that, when fulfilled, will either return an Object 
	 * with the total number of orders
	 * and number of orders with delivered status or an Error with the problem.
	 */
	deliveredOrders() {
		const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/stats/ecommerce/delivered-orders`,
            'GET'
        );
        return request.send();
	}
}