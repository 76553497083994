import _forEach from 'lodash/forEach';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';

/**
 * 
 * @param {*} str 
 */
export function convertStringToLowerCase(str) {
    return str.toLowerCase();
}

/**
 * 
 * @param {*} value 
 */
export function isNumber(value) {
    return typeof value === 'number' && Number.isFinite(value);
}

/**
 * 
 * @param {*} value 
 */
export function isInteger(value) {
    return Number.isInteger(value) && Number.isFinite(value);
}

/**
 * 
 * @param {*} value 
 */
export function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

/**
 * 
 * @param {*} value 
 */
export function isBoolean(value) {
    return typeof value === 'boolean';
}

/**
 * 
 * @param {*} value 
 */
export function isObject(value) {
    return value && typeof value === 'object' && Object.prototype.toString.call(value) === '[object Object]';
}

/**
 * 
 * @param {*} value 
 */
export function isArray(value) {
    return value && typeof value === 'object' && Array.isArray(value);
}

/**
 * 
 * @param {*} value 
 */
export function isDate(value) {
    return value instanceof Date && !isNaN(value.getTime());
}

export function isNull(value) {
    return value === null;
}

export function isUndefined(value) {
    return typeof value === 'undefined';
}

export function isNullOrUndefined(value) {
    return isNull(value) || isUndefined(value);
}

/**
 * Convert object keys from snake_case to camelCase naming convention.
 * Recursive conversion.
 * 
 * @param {*} snakeCaseObject 
 */
export function objectKeysToCamelCase(snakeCaseObject) {
    var camelCaseObject = isArray(snakeCaseObject) ? [] : {};
    _forEach(
        snakeCaseObject,
        function (value, key) {
            // checks that a value is a plain object or an array - for recursive key conversion
            if (isObject(value) || isArray(value)) {
                // recursively update keys of any values that are also objects
                value = objectKeysToCamelCase(value);
            }
            if (isArray(camelCaseObject)) {
                camelCaseObject.push(value);
            } else {
                camelCaseObject[_camelCase(key)] = value;
            }
        }
    )
    return camelCaseObject;
}

/**
 * Convert object keys from camelCase to snake_case naming convention.
 * Recursive conversion.
 * 
 * @param {*} camelCaseObject 
 */
export function objectKeysToSnakeCase(camelCaseObject) {
    var snakeCaseObject = isArray(camelCaseObject) ? [] : {};
    _forEach(
        camelCaseObject,
        function (value, key) {
            // checks that a value is a plain object or an array - for recursive key conversion
            if (isObject(value) || isArray(value)) {
                // recursively update keys of any values that are also objects
                value = objectKeysToSnakeCase(value);
            }
            if (isArray(snakeCaseObject)) {
                snakeCaseObject.push(value);
            } else {
                snakeCaseObject[camelToSnakeCase(key)] = value;
            }
        }
    )
    return snakeCaseObject;
}

/**
 * Convert string from camel to snake case.
 * 
 * @param {String} str The string that needs to be modified
 * @returns {String} New snake case string
 */
 export function camelToSnakeCase(str) {
    let out = str.replace(/[A-Z]/g, function(letter) {
        return `_${letter.toLowerCase()}`;
    });
    if(out.charAt(0) === '_') {
        return out.substring(1);
    }
    return out;
}

/**
 * Convert string from camel to snake case.
 * 
 * @param {String} str The string that needs to be modified
 * @returns {String} New camel case string
 */
export function snakeToCamelCase(str){
    return str.replace(/[^a-zA-Z0-9]+(.)/g, function(_, letter) {
        return letter.toUpperCase();
    });
}

/**
 * Capitalize the first letter of a given string.
 * 
 * @param {String} string The string that needs to be modified
 * @returns {String} Modified string 
 */
export function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
}

/**
 * Removes all properties which key name starts with underscore (_).
 * These properties are private properties of a class instance.
 * Input parameter is passed and processed as a reference.
 * 
 * @param {Object} data A JSON object with the data 
 */
export function removePrivateProps(data) {
    _forEach(
        data,
        function (value, key) {
            if (isObject(value) || isArray(value)) {
                // recursively update keys of any values that are also objects
                value = removePrivateProps(value);
            }
            if (isString(key) && key.startsWith('_')) {
                delete data[key];
            }
        }
    );
}

/**
 * Removes all properties which key name doesn't start with underscore (_).
 * Also removes all properties which key name starts with underscore (_) but have null values.
 * These properties are json properties of a class instance.
 * Input parameter is passed and processed as a reference.
 * 
 * @param {Object} data A JSON object with the data 
 */
export function extractJsonProps(data) {
    _forEach(
        data,
        function (value, key) {
            if (!isObjLiteral(value) && (isObject(value) || isArray(value))) {
                // recursively update keys of any values that are also objects
                value = extractJsonProps(value);
            }
            if (
                !isObjLiteral(value)
                && (
                    (isString(key) && !key.startsWith('_')) 
                    || (isString(key) && key.startsWith('_') && value === null) 
                )
            ) {
                delete data[key];
            }
        }
    );
}

export function isObjLiteral(obj) {
    return {}.toString.call(obj) == '[object Object]';
}

export function isEdgeBrowser() {
    return window.navigator.userAgent.indexOf("Edge") > -1;
}