import { APICall } from '../core';
import {
    convertStringToLowerCase,
    isArray,
    isString,
    isBoolean,
    isEdgeBrowser
} from '../../helpers/utils';
import Product from './Product';
import { UPLOAD_DIR } from '../../helpers/constants';

export default class ProductsService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * 
     * @param {Boolean} full Return full product's data (admin privilegies required)
     */
    getAll(full) {
        let returnFull = '';
        if (isBoolean(full) && full) {
            returnFull = '?f=full';
        }
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/products${returnFull}`,
            'GET'
        );
        return request.send();
    }

    /**
     * 
     * @param {String} id Product id
     * @param {Boolean} full Return full product's data (admin privilegies required)
     */
    getProductById(id, full) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        let returnFull = '';
        if (isBoolean(full) && full) {
            returnFull = '?f=full';
        }
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/products/${id}${returnFull}`,
            'GET'
        );
        return request.send();
    }

    /**
     * 
     * @param {*} products 
     */
    syncProductsIntoDB(products) {
        if (!isArray(products) || products.length == 0) {
            return Promise.reject({
                status: 0,
                message: 'Products list was not specified properly.'
            });
        }
        return this.bc.db.products
            .bulkPut(products)
            .then(() => {
                return Promise.resolve({
                    status: 1,
                    message: 'Products were successfully synced into IndexedDB.'
                });
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     */
    clearSyncedProducts() {
        return this.bc.db.products.clear()
            .then(() => {
                return Promise.resolve({
                    status: 1,
                    message: 'Synced products were successfully cleared from IndexedDB.'
                });
            })
            .catch(error => Promise.reject(error));
    }

    /**
     * 
     */
    getAllSynced() {
        return this.bc.db.products.toArray();
    }

    /**
     * 
     * @param {*} id 
     */
    getProductByIdSynced(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        return this.bc.db.products.get(id);
    }

    /**
     * Product search by name or tags.
     * 
     * @param {*} tags 
     */
    searchProductsByTagsSynced(tags) {
        if (!tags || !isArray(tags) || tags.length == 0) {
            return Promise.reject({
                status: 0,
                message: 'Tags parameter must be an array with at least one element.'
            });
        }
        let tagsToLowerCase = tags.map(item => convertStringToLowerCase(item));
        let query = !isEdgeBrowser()
            ?
            this.searchProductsByIndex(tagsToLowerCase)
            :
            this.searchProductsWithFilter(tagsToLowerCase);
        return query.toArray();
    }

    searchProductsByIndex(tags) {
        return this.bc.db.products
            .where('name')
            .startsWithAnyOfIgnoreCase(tags)
            .or('tags')
            .startsWithAnyOfIgnoreCase(tags)
            .distinct();
    }

    searchProductsWithFilter(tags) {
        return this.bc.db.products
            .filter(product => {
                return tags.some(tag => {
                    let cond1 = product.name.toLowerCase().startsWith(tag);
                    let cond2 = isArray(product.tags)
                        ?
                        product.tags.map(t => t.toLowerCase()).some(t => t.startsWith(tag))
                        :
                        false;
                    return cond1 || cond2;
                });
            });
    }

    /**
     * Insert new product.
     * 
     * @param {object} p Instance of the Product class
     * @return {Promise} 
     */
    insertProduct(p) {
        if (!(p instanceof Product)) {
            return Promise.reject({
                status: 0,
                message: 'Product data argument must be an instance of the Product class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid product data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Update product.
     * 
     * @param {string} id Product id
     * @param {object} p Instance of the Product class
     * @return {Promise} 
     */
    updateProduct(id, p) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        if (!(p instanceof Product)) {
            return Promise.reject({
                status: 0,
                message: 'Product data argument must be an instance of the Product class.'
            });
        }
        if (!p.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid product data.',
                errors: p.getValidationErrors()
            });
        }
        let payload = p.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * Delete product.
     * 
     * @param {string} id Product id
     * @return {Promise} 
     */
    deleteProduct(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Product id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/products/${id}`,
            'DELETE'
        );
        return request.send();
    }

    uploadImage(img) {
        if (!(img instanceof File)) {
            return Promise.reject({
                status: 0,
                message: 'Image should be a File object instance.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/upload`,
            'POST',
            {
                file: img,
                dir: UPLOAD_DIR.PRODUCTS
            }
        );
        return request.upload();
    }

    /**
     * 
     * @param {Boolean} full Return full product's data (admin privilegies required)
     */
     getAllV2(params) {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v2/products`,
            'GET'
        );
        request.setQueryParams(params);
        return request.send();
    }
}