import { APICall } from '../core';
import { isString, isNullOrUndefined } from '../../helpers/utils';
import Order from './Order';
import DeliveryTimeslot from './DeliveryTimeslot';
import DeliverySchedule from './DeliverySchedule';

export default class OrdersService {

    constructor(bc) {
        this.bc = bc;
    }

    /**
     * Gets all orders in the system.
     * The result will not include order details.
     * Requires valid token.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with
     * all orders in the system or an Error with the problem.
     */
    getAll() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders`,
            'GET'
        );
        return request.send();
    }

    /**
     * Gets order with specified id.
     * This method will also return order's details, including the order items.
     * Requires valid token.
     * 
     * @param {String} id Order id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * order's details or an Error with the problem.
     */
    getOrderById(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Order id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/${id}`,
            'GET'
        );
        return request.send();
    }

    /**
     * Gets all orders of the logged in user.
     * The result will not include order details.
     * Requires valid token.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with
     * user's orders or an Error with the problem.
     */
    getMyOrders() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/user/me`,
            'GET'
        );
        return request.send();
    }

    /**
     * Gets all orders of the user specified by id.
     * The result will not include order details.
     * Requires valid token.
     * 
     * @param {String} id User id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * user's orders or an Error with the problem.
     */
    getAllUserOrders(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'User id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/user/${id}`,
            'GET'
        );
        return request.send();
    }

    /**
     * Gets user's active order.
     * The order must matches user's visitor id value, and its status to be 'NEW' or 'CHARGEABLE'.
     * 
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * order's details or an Error with the problem.
     */
    getCurrentOrder() {
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/user/me`,
            'GET'
        );
        return request.send();
    }

    /**
     * Creates an order from a shopping cart.
     * 
     * @param {String} id Shopping cart id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * order's details or an Error with the problem.
     */
    createOrder(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Cart id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/${id}`,
            'POST'
        );
        return request.send();
    }

    /**
     * Updates order.
     * 
     * @param {String} id Order id
     * @param {Object} o Instance of the Order class
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status 
     * or an Error with the problem.
     */
    updateOrder(id, o) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Order id was not specified properly.'
            });
        }
        if (!(o instanceof Order)) {
            return Promise.reject({
                status: 0,
                message: 'Order data argument must be an instance of the Order class.'
            });
        }
        if (!o.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid order data.',
                errors: o.getValidationErrors()
            });
        }
        let payload = o.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * Deletes order item.
     * 
     * @param {String} id Order id
     * @param {String} iid Item id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status 
     * or an Error with the problem.
     */
    deleteOrderItem(id, iid) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Order id was not specified properly.'
            });
        }
        if (!isString(iid) || iid === '') {
            return Promise.reject({
                status: 0,
                message: 'Item id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/${id}/items/${iid}`,
            'DELETE'
        );
        return request.send();
    }

    /**
     * Deletes order.
     * 
     * @param {String} id Order id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status 
     * or an Error with the problem.
     */
    deleteOrder(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Order id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/${id}`,
            'DELETE'
        );
        return request.send();
    }

    /**
     * Updates order's status.
     * 
     * @param {String} id Order id
     * @param {String} status New order status
     * @param {String} message Status message (only when status is changed to 'canceled')
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status 
     * or an Error with the problem.
     */
    updateOrderStatus(id, status, message) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Order id was not specified properly.'
            });
        }
        if (!isString(status) || status === '') {
            return Promise.reject({
                status: 0,
                message: 'Order status was not specified properly.'
            });
        }
        if (!isNullOrUndefined(message) && !isString(message) || message === '') {
            return Promise.reject({
                status: 0,
                message: 'Order status message was not specified properly.'
            });
        }
        let payload = {
            status: status,
            status_message: message
        };
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/${id}/status`,
            'PUT',
            payload
        );
        return request.send();
    }

    getTimeslots() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/orders/timeslots`,
            'GET'
        );
        return request.send();
    }

    createTimeslot(dt) {
        if (!(dt instanceof DeliveryTimeslot)) {
            return Promise.reject({
                status: 0,
                message: 'Delivery timeslot data argument must be an instance of the DeliveryTimeslot class.'
            });
        }
        if (!dt.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid timeslot data.',
                errors: dt.getValidationErrors()
            });
        }
        let payload = dt.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/timeslots`,
            'POST',
            payload
        );
        return request.send();
    }

    updateTimeslot(id, dt) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Timeslot id was not specified properly.'
            });
        }
        if (!(dt instanceof DeliveryTimeslot)) {
            return Promise.reject({
                status: 0,
                message: 'Delivery timeslot data argument must be an instance of the DeliveryTimeslot class.'
            });
        }
        if (!dt.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid timeslot data.',
                errors: dt.getValidationErrors()
            });
        }
        let payload = dt.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/timeslots/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    removeTimeslot(id) {
        if (!id || !isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Timeslot id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/timeslots/${id}`,
            'DELETE'
        );
        return request.send();
    }

    /**
     * Get all delivery schedule records.
     *
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * delivery schedule records or an Error with the problem.
     */
    getDeliverySchedules() {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/orders/schedule`,
            'GET'
        );
        return request.send();
    }

    /**
     * Create new delivery schedule record.
     *
     * @param {DeliverySchedule} ds delivery schedule data
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Object with the
     * delivery schedule or an Error with the problem.
     */
    createDeliverySchedule(ds) {
        if (!(ds instanceof DeliverySchedule)) {
            return Promise.reject({
                status: 0,
                message: 'Delivery schedule data argument must be an instance of the DeliverySchedule class.'
            });
        }
        if (!ds.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid schedule data.',
                errors: ds.getValidationErrors()
            });
        }
        let payload = ds.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/schedule`,
            'POST',
            payload
        );
        return request.send();
    }

    /**
     * Updates delivery schedule.
     * 
     * @param {String} id schedule id
     * @param {DeliverySchedule} ds delivery schedule data
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status 
     * or an Error with the problem.
     */
    updateDeliverySchedule(id, ds) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Schedule id was not specified properly.'
            });
        }
        if (!(ds instanceof DeliverySchedule)) {
            return Promise.reject({
                status: 0,
                message: 'Delivery timeslot data argument must be an instance of the DeliverySchedule class.'
            });
        }
        if (!ds.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid schedule data.',
                errors: ds.getValidationErrors()
            });
        }
        let payload = ds.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/schedule/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    /**
     * Delete delivery schedule.
     *
     * @param {String} id schedule id
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return success status
     * or an Error with the problem.
     */
    removeDeliverySchedule(id) {
        if (!id || !isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Schedule id was not specified properly.'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/orders/schedule/${id}`,
            'DELETE'
        );
        return request.send();
    }

    /**
     * Get all delivery tmeslots for a given day.
     *
     * @param {String} date delivery date in the following format 'YYYY-MM-DD'
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with the
     * delivery schedule records or an Error with the problem.
     */
    getTimeslotsForDate(date) {
        const request = new APICall(
            this.bc.apiKey,
            null,
            `${this.bc.baseUrl}/v1/orders/delivery/timeslots?date=${date}`,
            'GET'
        );
        return request.send();
    }

    /**
     * Gets all orders in the system.
     * The result will not include order details.
     * Requires valid token.
     * 
     * @param {Object} params an object with parameters used for creation of the querystring
     * @returns {Promise} Returns a Promise that, when fulfilled, will either return an Array with
     * all orders in the system or an Error with the problem.
     */
     getAllV2(params) {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v2/orders`,
            'GET'
        );
        request.setQueryParams(params);
        return request.send();
    }
}