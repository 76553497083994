import { APICall } from '../core';
import { isString } from '../../helpers/utils';
import MessageForm from './message/MessageForm';
import MessageFormData from './message/MessageFormData';
import { UPLOAD_DIR } from '../../helpers/constants';

export default class FormsService {

    constructor(bc) {
        this.bc = bc;
    }

    getAllMessageForms() {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message`,
            'GET',
        );
        return request.send();
    }

    getMessageForm(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Message form id was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message/${id}`,
            'GET',
        );
        return request.send();
    }

    createMessageForm(mf) {
        if (!(mf instanceof MessageForm)) {
            return Promise.reject({
                status: 0,
                message: 'Message form data argument must be an instance of the MessageForm class'
            });
        }
        if (!mf.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid message form data',
                errors: mf.getValidationErrors()
            });
        }
        let payload = mf.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message`,
            'POST',
            payload
        );
        return request.send();
    }

    updateMessageForm(id, mf) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Message form id was not specified properly'
            });
        }
        if (!(mf instanceof MessageForm)) {
            return Promise.reject({
                status: 0,
                message: 'Message form data argument must be an instance of the MessageForm class'
            });
        }
        if (!mf.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid message form data',
                errors: mf.getValidationErrors()
            });
        }
        let payload = mf.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message/${id}`,
            'PUT',
            payload
        );
        return request.send();
    }

    deleteMessageForm(id) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Message form id was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message/${id}`,
            'DELETE'
        );
        return request.send();
    }

    postMessage(permalink, mf) {
        if (!(mf instanceof MessageFormData)) {
            return Promise.reject({
                status: 0,
                message: 'Message data argument must be an instance of the MessageFormData class.'
            });
        }
        if (!mf.validate()) {
            return Promise.reject({
                status: 0,
                message: 'Invalid message data',
                errors: mf.getValidationErrors()
            });
        }
        let payload = mf.loadToJSON();
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message/${permalink}`,
            'POST',
            payload
        );
        return request.send();
    }

    getMessages(permalink) {
        if (!isString(permalink) || permalink === '') {
            return Promise.reject({
                status: 0,
                message: 'Form permalink was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message/${permalink}/messages`,
            'GET',
        );
        return request.send();
    }

    getSingleMessage(id, permalink) {
        if (!isString(id) || id === '') {
            return Promise.reject({
                status: 0,
                message: 'Message id was not specified properly'
            });
        }
        if (!isString(permalink) || permalink === '') {
            return Promise.reject({
                status: 0,
                message: 'Form permalink was not specified properly'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/forms/message/${permalink}/messages/${id}`,
            'GET',
        );
        return request.send();
    }

    uploadFile(file) {
        if (!(file instanceof File)) {
            return Promise.reject({
                status: 0,
                message: 'Files should be a File object instance'
            });
        }
        const request = new APICall(
            this.bc.apiKey,
            !this.bc.auth.jwtData ? null : this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v1/upload`,
            'POST',
            {
                file: file,
                dir: UPLOAD_DIR.FILES
            }
        );
        return request.upload();
    }

    getAllMessageFormsV2(params) {
        const request = new APICall(
            this.bc.apiKey,
            this.bc.auth.jwtData.csrf,
            `${this.bc.baseUrl}/v2/forms/message`,
            'GET',
        );
        request.setQueryParams(params);
        return request.send();
    }

}